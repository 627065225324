import React, { useState } from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "../../apollo/client"

export const context = React.createContext()

const Provider = ({ children }) => {

  //Recipes in cups or grams
  const [preciseIngredients, setPrecision] = useState(false)
  const togglePrecision = () => setPrecision(!preciseIngredients)

  //Map for difficulty levels
  const difficultyTiers = [
    { value: 10, label: "Fácil" },
    { value: 20, label: "Intermediário" },
    { value: 30, label: "Desafiador" },
  ]

  return (
    <context.Provider
      value={{
        preciseIngredients,
        togglePrecision,
        difficultyTiers,
      }}
    >
      {/* Context for preview */}
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </context.Provider>
  )
}

const MainProvider = ({ element }) => <Provider>{element}</Provider>
export default MainProvider
