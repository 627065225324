// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cursos-js": () => import("./../../../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-inspiracoes-js": () => import("./../../../src/pages/inspiracoes.js" /* webpackChunkName: "component---src-pages-inspiracoes-js" */),
  "component---src-pages-preview-inspiration-js": () => import("./../../../src/pages/preview-inspiration.js" /* webpackChunkName: "component---src-pages-preview-inspiration-js" */),
  "component---src-pages-preview-post-js": () => import("./../../../src/pages/preview-post.js" /* webpackChunkName: "component---src-pages-preview-post-js" */),
  "component---src-pages-receitas-js": () => import("./../../../src/pages/receitas.js" /* webpackChunkName: "component---src-pages-receitas-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-frontpage-index-js": () => import("./../../../src/templates/Frontpage/index.js" /* webpackChunkName: "component---src-templates-frontpage-index-js" */),
  "component---src-templates-inspiration-index-js": () => import("./../../../src/templates/Inspiration/index.js" /* webpackChunkName: "component---src-templates-inspiration-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/Page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/Post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */)
}

