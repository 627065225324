import { ApolloLink, HttpLink, ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { relayStylePagination } from "@apollo/client/utilities";
import fetch from "isomorphic-fetch"

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        posts: relayStylePagination(),
      },
    },
  },
})

export const client = new ApolloClient({
  cache,
  fetch,
  link: ApolloLink.from([
    setContext((req, prev) => {
      if (!!req.variables.nonce) {
        return {
          headers: {
            ...prev.headers,
            "X-WP-Nonce": req.variables.nonce,
          },
        }
      } else {
        return {
          headers: {
            ...prev.headers,
          },
        }
      }
    }),
    new HttpLink({
      uri: "https://painel.guipoulain.com/graphql",
      credentials: "include",
    }),
  ]),
})
